import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../_services/index";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../../../_services/shared.service";
import { Router, NavigationEnd } from "@angular/router";
import { EnvService } from "../../../_services/index";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  lang: any = {active: localStorage.getItem('lang')};
  USER: any;
  env: any;

  constructor(
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private _SharedService: SharedService,
    private router: Router,
    private _Env: EnvService
  ) {
    this.env = this._Env;
    this.lang['active'] = this.lang['active'] === null ? 'en' : this.lang['active'];
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.USER = JSON.parse(localStorage.getItem('currentUser'));
      }
    });
  }

  ngOnInit() {}

  public logout(): void {
    this.authenticationService.logout();
  }

  public useLanguage(lang): void {
    this.lang.active = lang;
    this.translate.use(lang);
    console.log(lang);
    localStorage.setItem("lang", lang);
    this._SharedService.changeLanguageEvent.emit(lang);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from "@angular/router";

// MAIN MODULES
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { SharedModule } from "./shared/modules/shared.module";

// SERVICES
import { EnvServiceProvider, SharedService, AlertService, AuthenticationService, PlayersService, ProsService, LearningService, PaymentsService, AwsHandlerService } from './_services';

// GUARDS
import { AuthGuard } from './_guards';

// INTERCEPTORS
import { JwtInterceptor, EncodeHttpParamsInterceptor } from './_helpers';

// EXTERNAL MODULES
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// COMPONENTS
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { NotFound404Component } from './not-found404/not-found404.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ClubsComponent } from './clubs/clubs.component';



import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
defineLocale('en-gb', enGbLocale);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    DashboardComponent,
    LoginComponent,
    NotFound404Component,
    StatisticsComponent,
    ClubsComponent
  ],
  imports: [
    SharedModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    LoadingBarHttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  providers: [
    EnvServiceProvider,
    AuthGuard,
    AuthenticationService,
    SharedService,
    AlertService,
    PlayersService,
    ProsService,
    LearningService,
    PaymentsService,
    AwsHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

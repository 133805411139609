import { Directive, ViewContainerRef, ElementRef, Renderer2, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as AWS from 'aws-sdk';
import {EnvService} from "../_services";

@Directive({
  selector: '[appAwsTunnel]'
})
export class AwsTunnelDirective implements OnInit {

  @Output() awsEventEmit = new EventEmitter<any>();
  
  @Input() src: string;
  @Input() alt: string;
  @Input() photo_id: number;
  @Input() photo_status: number;
  s3: any;
  
  env: any;
  
  constructor(
    private _ref: ViewContainerRef,
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _env: EnvService
  ) {
    this.env = _env;
  }
  
  ngOnInit() {
    let pathToImage = this.src.split("amazonaws.com/").pop();
    this.s3 = new AWS.S3({
      region: this.env.region,
      signatureVersion: this.env.signatureVersion,
      accessKeyId: this.env.accessKeyId,
      secretAccessKey: this.env.secretAccessKey
    });
    let params = {
      Bucket: this.env.Bucket,
      Key: pathToImage
    };
    this.s3.getObject(params, function (err, data) {
      return data;
    }).promise()
      .then(res => {
        // console.log(res);
        let url = window.URL;
        let blob = new Blob([res.Body], {type: "image/jpeg"});
        this._renderer.setAttribute(this._el.nativeElement, 'src', url.createObjectURL(blob));
        this.handleEvent({
          src: this.src,
          alt: this.alt,
          photo_id: this.photo_id,
          photo_status: this.photo_status,
          blob: url.createObjectURL(blob)
        });
      }, err => {
        console.log(err);
      });
  }
  /*this.s3.getObject(params, res => {
      console.log(res);
      /!*let urlCreator = window.URL || window.webkitURL;
      let blob = new Blob([ file.Body ], { type: "image/jpeg" });
      elem.attr('src', urlCreator.createObjectURL(blob));*!/
      return res;
    }, err => {
      console.log(err);
      return err;
    });*/

  public handleEvent(data) {
    this.awsEventEmit.emit(data);
  }
  
}

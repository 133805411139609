import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { EnvService } from "./env.service";
import { AlertService } from "./alert.service";
import { User } from "../_models";

@Injectable()
export class AuthenticationService {
  
  constructor(
    private env: EnvService,
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService
  ) {}
  
  login(email: string, password: string) {
    return this.http.post<any>(`${this.env.apiBase}/web/operator/login`, {
      email: email,
      password: password
    }).subscribe(res => {
      let user = new User(res['result']);
      if (user && user['api_key']) {
        // login successful if there's a jwt token in the response
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.alertService.success('Alert.Successful_login');
        this.router.navigate(['/']);
      }
    }, err => {
      if (err['status'] >= 401 && err['status'] <= 499) {
        this.alertService.error('Alert.Wrong_email_or_password');
      } else {
        this.alertService.error(err['statusText']);
      }
    });
  }
  
  logout(): void {
    let tempLang = localStorage.getItem('lang');
    localStorage.clear();
    localStorage.setItem('lang', tempLang);
    this.router.navigate(['/login']);
  }
  
}

<div class="container statistics-page-tpl">
  <div class="row">
    <div class="top-bar col-12">
      <div class="breadcrumbs">
        <span class="breadcrumb-item">
          <a [routerLink]="['/statistics']">
            {{'Statistics.Overall_statistics' | translate}}
          </a>
        </span>
      </div>
      <div class="controls"></div>
    </div>
    <div class="main-content col-12">
      <div class="filters-wrapper">
        <div class="filters">
          <div class="server-side-filters">
            <form class="clubFilterForm" name="clubFilterForm" id="clubFilterForm" [formGroup]="clubFilterForm"  #f="ngForm" novalidate>
              <div class="form-group">
                <div class="input-wrapper club-filter-wrapper">
                  <ng-select [items]="clubList"
                             bindLabel="name"
                             bindValue="id"
                             appendTo=".input-wrapper.club-filter-wrapper"
                             [multiple]="false"
                             [notFoundText]="'Shared.No_clubs_found' | translate"
                             [placeholder]="'Shared.Select_club' | translate"
                             [searchable]="true"
                             [closeOnSelect]="true"
                             [hideSelected]="false"
                             [selectableGroup]="false"
                             (change)="clubFilterSubmit($event)"
                             formControlName="club">
                  </ng-select>
                </div>
              </div>
            </form>
            <button class="secondary-action-btn no-icon-btn" (click)="openImportCSVCoursesModal(importCSVCoursesModal)">
              <span>{{ 'Statistics.Import_csv_courses_btn' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="datatable-wrapper stats scoring-stats">
        <div class="table-title-wrapper">
          <h5 class="table-title">{{ 'Statistics.Scoring_table_title' | translate }}</h5>
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr class="top-header">
              <th class="blank stats-params"></th>
              <th class="plus-handicap">
                <span>{{'Statistics.Plus_handicap_th' | translate}}</span>
              </th>
              <th class="first_handicap_range">
                <span>{{'Statistics.First_handicap_range_th' | translate}}</span>
              </th>
              <th class="second_handicap_range">
                <span>{{'Statistics.Second_handicap_range_th' | translate}}</span>
              </th>
              <th class="third_handicap_range">
                <span>{{'Statistics.Third_handicap_range_th' | translate}}</span>
              </th>
              <th class="fourth_handicap_range">
                <span>{{'Statistics.Fourth_handicap_range_th' | translate}}</span>
              </th>
              <th class="fifth_handicap_range">
                <span>{{'Statistics.Fifth_handicap_range_th' | translate}}</span>
              </th>
              <th class="sixth_handicap_range">
                <span>{{'Statistics.Sixth_handicap_range_th' | translate}}</span>
              </th>
            </tr>
            <tr class="additional-header" *ngIf="stats">
              <th class="row-name">
                Number of players
              </th>
              <th class="plus-handicap">
                {{stats['Plus handicap +0.1 - +5.0']['count_players']}}
              </th>
              <th class="first_handicap_range">
                {{stats['0.0-5.0']['count_players']}}
              </th>
              <th class="second_handicap_range">
                {{stats['5.1-10.0']['count_players']}}
              </th>
              <th class="third_handicap_range">
                {{stats['10.1-15.0']['count_players']}}
              </th>
              <th class="fourth_handicap_range">
                {{stats['15.1-20.0']['count_players']}}
              </th>
              <th class="fifth_handicap_range">
                {{stats['20.1-54.1']['count_players']}}
              </th>
              <th class="sixth_handicap_range">
                {{stats['54.1 and higher']['count_players']}}
              </th>
            </tr>
            </thead>
            <tbody *ngIf="scoringStats">
            <tr class="hover_rows" *ngFor="let i of scoringStats">
              <td>
                {{i.param}}
              </td>
              <td>
                {{i.plus_handicap}}
              </td>
              <td>
                {{i.first_handicap_range}}
              </td>
              <td>
                {{i.second_handicap_range}}
              </td>
              <td>
                {{i.third_handicap_range}}
              </td>
              <td>
                {{i.fourth_handicap_range}}
              </td>
              <td>
                {{i.fifth_handicap_range}}
              </td>
              <td>
                {{i.sixth_handicap_range}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="datatable-wrapper stats fir-stats">
        <div class="table-title-wrapper">
          <h5 class="table-title">{{ 'Statistics.Fir_table_title' | translate }}</h5>
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr class="top-header">
              <th class="blank stats-params"></th>
              <th class="plus-handicap">
                <span>{{'Statistics.Plus_handicap_th' | translate}}</span>
              </th>
              <th class="first_handicap_range">
                <span>{{'Statistics.First_handicap_range_th' | translate}}</span>
              </th>
              <th class="second_handicap_range">
                <span>{{'Statistics.Second_handicap_range_th' | translate}}</span>
              </th>
              <th class="third_handicap_range">
                <span>{{'Statistics.Third_handicap_range_th' | translate}}</span>
              </th>
              <th class="fourth_handicap_range">
                <span>{{'Statistics.Fourth_handicap_range_th' | translate}}</span>
              </th>
              <th class="fifth_handicap_range">
                <span>{{'Statistics.Fifth_handicap_range_th' | translate}}</span>
              </th>
              <th class="sixth_handicap_range">
                <span>{{'Statistics.Sixth_handicap_range_th' | translate}}</span>
              </th>
            </tr>
            <tr class="additional-header" *ngIf="stats">
              <th class="row-name">
                Number of players
              </th>
              <th class="plus-handicap">
                {{stats['Plus handicap +0.1 - +5.0']['count_players']}}
              </th>
              <th class="first_handicap_range">
                {{stats['0.0-5.0']['count_players']}}
              </th>
              <th class="second_handicap_range">
                {{stats['5.1-10.0']['count_players']}}
              </th>
              <th class="third_handicap_range">
                {{stats['10.1-15.0']['count_players']}}
              </th>
              <th class="fourth_handicap_range">
                {{stats['15.1-20.0']['count_players']}}
              </th>
              <th class="fifth_handicap_range">
                {{stats['20.1-54.1']['count_players']}}
              </th>
              <th class="sixth_handicap_range">
                {{stats['54.1 and higher']['count_players']}}
              </th>
            </tr>
            </thead>
            <tbody *ngIf="scoringStats">
            <tr class="hover_rows" *ngFor="let i of firStats">
              <td>
                {{i.param}}
              </td>
              <td>
                {{i.plus_handicap}}
              </td>
              <td>
                {{i.first_handicap_range}}
              </td>
              <td>
                {{i.second_handicap_range}}
              </td>
              <td>
                {{i.third_handicap_range}}
              </td>
              <td>
                {{i.fourth_handicap_range}}
              </td>
              <td>
                {{i.fifth_handicap_range}}
              </td>
              <td>
                {{i.sixth_handicap_range}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="datatable-wrapper stats gir-stats">
        <div class="table-title-wrapper">
          <h5 class="table-title">{{ 'Statistics.Gir_table_title' | translate }}</h5>
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr class="top-header">
              <th class="blank stats-params"></th>
              <th class="plus-handicap">
                <span>{{'Statistics.Plus_handicap_th' | translate}}</span>
              </th>
              <th class="first_handicap_range">
                <span>{{'Statistics.First_handicap_range_th' | translate}}</span>
              </th>
              <th class="second_handicap_range">
                <span>{{'Statistics.Second_handicap_range_th' | translate}}</span>
              </th>
              <th class="third_handicap_range">
                <span>{{'Statistics.Third_handicap_range_th' | translate}}</span>
              </th>
              <th class="fourth_handicap_range">
                <span>{{'Statistics.Fourth_handicap_range_th' | translate}}</span>
              </th>
              <th class="fifth_handicap_range">
                <span>{{'Statistics.Fifth_handicap_range_th' | translate}}</span>
              </th>
              <th class="sixth_handicap_range">
                <span>{{'Statistics.Sixth_handicap_range_th' | translate}}</span>
              </th>
            </tr>
            <tr class="additional-header" *ngIf="stats">
              <th class="row-name">
                Number of players
              </th>
              <th class="plus-handicap">
                {{stats['Plus handicap +0.1 - +5.0']['count_players']}}
              </th>
              <th class="first_handicap_range">
                {{stats['0.0-5.0']['count_players']}}
              </th>
              <th class="second_handicap_range">
                {{stats['5.1-10.0']['count_players']}}
              </th>
              <th class="third_handicap_range">
                {{stats['10.1-15.0']['count_players']}}
              </th>
              <th class="fourth_handicap_range">
                {{stats['15.1-20.0']['count_players']}}
              </th>
              <th class="fifth_handicap_range">
                {{stats['20.1-54.1']['count_players']}}
              </th>
              <th class="sixth_handicap_range">
                {{stats['54.1 and higher']['count_players']}}
              </th>
            </tr>
            </thead>
            <tbody *ngIf="scoringStats">
            <tr class="hover_rows" *ngFor="let i of girStats">
              <td>
                {{i.param}}
              </td>
              <td>
                {{i.plus_handicap}}
              </td>
              <td>
                {{i.first_handicap_range}}
              </td>
              <td>
                {{i.second_handicap_range}}
              </td>
              <td>
                {{i.third_handicap_range}}
              </td>
              <td>
                {{i.fourth_handicap_range}}
              </td>
              <td>
                {{i.fifth_handicap_range}}
              </td>
              <td>
                {{i.sixth_handicap_range}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="datatable-wrapper stats upAndDown-stats">
        <div class="table-title-wrapper">
          <h5 class="table-title">{{ 'Statistics.upAndDown_table_title' | translate }}</h5>
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr class="top-header">
              <th class="blank stats-params"></th>
              <th class="plus-handicap">
                <span>{{'Statistics.Plus_handicap_th' | translate}}</span>
              </th>
              <th class="first_handicap_range">
                <span>{{'Statistics.First_handicap_range_th' | translate}}</span>
              </th>
              <th class="second_handicap_range">
                <span>{{'Statistics.Second_handicap_range_th' | translate}}</span>
              </th>
              <th class="third_handicap_range">
                <span>{{'Statistics.Third_handicap_range_th' | translate}}</span>
              </th>
              <th class="fourth_handicap_range">
                <span>{{'Statistics.Fourth_handicap_range_th' | translate}}</span>
              </th>
              <th class="fifth_handicap_range">
                <span>{{'Statistics.Fifth_handicap_range_th' | translate}}</span>
              </th>
              <th class="sixth_handicap_range">
                <span>{{'Statistics.Sixth_handicap_range_th' | translate}}</span>
              </th>
            </tr>
            <tr class="additional-header" *ngIf="stats">
              <th class="row-name">
                Number of players
              </th>
              <th class="plus-handicap">
                {{stats['Plus handicap +0.1 - +5.0']['count_players']}}
              </th>
              <th class="first_handicap_range">
                {{stats['0.0-5.0']['count_players']}}
              </th>
              <th class="second_handicap_range">
                {{stats['5.1-10.0']['count_players']}}
              </th>
              <th class="third_handicap_range">
                {{stats['10.1-15.0']['count_players']}}
              </th>
              <th class="fourth_handicap_range">
                {{stats['15.1-20.0']['count_players']}}
              </th>
              <th class="fifth_handicap_range">
                {{stats['20.1-54.1']['count_players']}}
              </th>
              <th class="sixth_handicap_range">
                {{stats['54.1 and higher']['count_players']}}
              </th>
            </tr>
            </thead>
            <tbody *ngIf="scoringStats">
            <tr class="hover_rows" *ngFor="let i of upAndDownStats">
              <td>
                {{i.param}}
              </td>
              <td>
                {{i.plus_handicap}}
              </td>
              <td>
                {{i.first_handicap_range}}
              </td>
              <td>
                {{i.second_handicap_range}}
              </td>
              <td>
                {{i.third_handicap_range}}
              </td>
              <td>
                {{i.fourth_handicap_range}}
              </td>
              <td>
                {{i.fifth_handicap_range}}
              </td>
              <td>
                {{i.sixth_handicap_range}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="datatable-wrapper stats putting-stats">
        <div class="table-title-wrapper">
          <h5 class="table-title">{{ 'Statistics.Putting_table_title' | translate }}</h5>
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr class="top-header">
              <th class="blank stats-params"></th>
              <th class="plus-handicap">
                <span>{{'Statistics.Plus_handicap_th' | translate}}</span>
              </th>
              <th class="first_handicap_range">
                <span>{{'Statistics.First_handicap_range_th' | translate}}</span>
              </th>
              <th class="second_handicap_range">
                <span>{{'Statistics.Second_handicap_range_th' | translate}}</span>
              </th>
              <th class="third_handicap_range">
                <span>{{'Statistics.Third_handicap_range_th' | translate}}</span>
              </th>
              <th class="fourth_handicap_range">
                <span>{{'Statistics.Fourth_handicap_range_th' | translate}}</span>
              </th>
              <th class="fifth_handicap_range">
                <span>{{'Statistics.Fifth_handicap_range_th' | translate}}</span>
              </th>
              <th class="sixth_handicap_range">
                <span>{{'Statistics.Sixth_handicap_range_th' | translate}}</span>
              </th>
            </tr>
            <tr class="additional-header" *ngIf="stats">
              <th class="row-name">
                Number of players
              </th>
              <th class="plus-handicap">
                {{stats['Plus handicap +0.1 - +5.0']['count_players']}}
              </th>
              <th class="first_handicap_range">
                {{stats['0.0-5.0']['count_players']}}
              </th>
              <th class="second_handicap_range">
                {{stats['5.1-10.0']['count_players']}}
              </th>
              <th class="third_handicap_range">
                {{stats['10.1-15.0']['count_players']}}
              </th>
              <th class="fourth_handicap_range">
                {{stats['15.1-20.0']['count_players']}}
              </th>
              <th class="fifth_handicap_range">
                {{stats['20.1-54.1']['count_players']}}
              </th>
              <th class="sixth_handicap_range">
                {{stats['54.1 and higher']['count_players']}}
              </th>
            </tr>
            </thead>
            <tbody *ngIf="scoringStats">
            <tr class="hover_rows" *ngFor="let i of puttingStats">
              <td>
                {{i.param}}
              </td>
              <td>
                {{i.plus_handicap}}
              </td>
              <td>
                {{i.first_handicap_range}}
              </td>
              <td>
                {{i.second_handicap_range}}
              </td>
              <td>
                {{i.third_handicap_range}}
              </td>
              <td>
                {{i.fourth_handicap_range}}
              </td>
              <td>
                {{i.fifth_handicap_range}}
              </td>
              <td>
                {{i.sixth_handicap_range}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="datatable-wrapper stats penalties-stats">
        <div class="table-title-wrapper">
          <h5 class="table-title">{{ 'Statistics.Penalties_table_title' | translate }}</h5>
        </div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr class="top-header">
              <th class="blank stats-params"></th>
              <th class="plus-handicap">
                <span>{{'Statistics.Plus_handicap_th' | translate}}</span>
              </th>
              <th class="first_handicap_range">
                <span>{{'Statistics.First_handicap_range_th' | translate}}</span>
              </th>
              <th class="second_handicap_range">
                <span>{{'Statistics.Second_handicap_range_th' | translate}}</span>
              </th>
              <th class="third_handicap_range">
                <span>{{'Statistics.Third_handicap_range_th' | translate}}</span>
              </th>
              <th class="fourth_handicap_range">
                <span>{{'Statistics.Fourth_handicap_range_th' | translate}}</span>
              </th>
              <th class="fifth_handicap_range">
                <span>{{'Statistics.Fifth_handicap_range_th' | translate}}</span>
              </th>
              <th class="sixth_handicap_range">
                <span>{{'Statistics.Sixth_handicap_range_th' | translate}}</span>
              </th>
            </tr>
            <tr class="additional-header" *ngIf="stats">
              <th class="row-name">
                Number of players
              </th>
              <th class="plus-handicap">
                {{stats['Plus handicap +0.1 - +5.0']['count_players']}}
              </th>
              <th class="first_handicap_range">
                {{stats['0.0-5.0']['count_players']}}
              </th>
              <th class="second_handicap_range">
                {{stats['5.1-10.0']['count_players']}}
              </th>
              <th class="third_handicap_range">
                {{stats['10.1-15.0']['count_players']}}
              </th>
              <th class="fourth_handicap_range">
                {{stats['15.1-20.0']['count_players']}}
              </th>
              <th class="fifth_handicap_range">
                {{stats['20.1-54.1']['count_players']}}
              </th>
              <th class="sixth_handicap_range">
                {{stats['54.1 and higher']['count_players']}}
              </th>
            </tr>
            </thead>
            <tbody *ngIf="scoringStats">
            <tr class="hover_rows" *ngFor="let i of penaltiesStats">
              <td>
                {{i.param}}
              </td>
              <td>
                {{i.plus_handicap}}
              </td>
              <td>
                {{i.first_handicap_range}}
              </td>
              <td>
                {{i.second_handicap_range}}
              </td>
              <td>
                {{i.third_handicap_range}}
              </td>
              <td>
                {{i.fourth_handicap_range}}
              </td>
              <td>
                {{i.fifth_handicap_range}}
              </td>
              <td>
                {{i.sixth_handicap_range}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #importCSVCoursesModal>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'Statistics.Import_csv_modal_title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="importCSVCoursesForm" name="importCSVCoursesForm" id="importCSVCoursesForm" [formGroup]="importCSVCoursesForm"  #f="ngForm" (ngSubmit)="importCSVCoursesForm.valid && csvCoursesFileSubmit($event)" [class.ng-submitted-error]="f.submitted && f.form.invalid" novalidate>
      <div class="form-group">
        <div class="input-wrapper file_input">
          <label for="csv_file">{{ 'Statistics.Select_file_coursess_label' | translate }}</label>
          <input type="file" class="form-control" accept=".csv" name="csv_file" id="csv_file" formControlName="csv_file"/>
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && importCSVCoursesForm.controls.csv_file.errors">
          <p *ngIf="importCSVCoursesForm.controls.csv_file.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!importCSVCoursesForm.controls.csv_file.errors.required && importCSVCoursesForm.controls.csv_file.errors.invalidFormat">{{'Shared.Invalid_file_format' | translate:{file_format: '.csv'} }}</p>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button [disabled]="btnLoading" class="modal-submit-btn no-icon-btn" form="importCSVCoursesForm">
      <span>{{ 'Pros.Submit_file_modal_btn' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>

import {Component, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { StatisticsService, ProsService, AlertService } from "../_services/index";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap";
import {FileExtentionValidatorDirective, FileValidator} from "../_directives/index";
import { EnvService } from "../_services/env.service";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.less'],
  providers: [FileExtentionValidatorDirective]
})
export class StatisticsComponent implements OnInit {

  clubFilterForm: FormGroup;
  importCSVCoursesForm: FormGroup;

  modalRef: BsModalRef;
  btnLoading = false;

  environtment: any;

  scoringStats = [
    {
      param: 'SCORE',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'PAR 3',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'PAR 4',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'PAR 5',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'HOLE IN ONE',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'ALBATROSS',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'EAGLES',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'BIRDIES',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'PARS',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'BOGEY',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'D.BOGEY',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'TRIPLE OR WORSE',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    }
  ];
  firStats = [
    {
      param: 'Fir (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Missed Fir left (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Missed Fir right (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    }
  ];
  girStats = [
    {
      param: 'Gir (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Missed Gir left (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Missed Gir right (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Missed Gir long (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Missed Gir short (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    }
  ];
  upAndDownStats = [
    {
      param: 'Fringe average (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Rough average (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Bunker average (%)',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    }
  ];
  puttingStats = [
    {
      param: 'Puts',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Short putts',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Middle putts',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    },
    {
      param: 'Long putts',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    }
  ];
  penaltiesStats = [
    {
      param: 'Penalties',
      plus_handicap : '-',
      first_handicap_range : '-',
      second_handicap_range : '-',
      third_handicap_range : '-',
      fourth_handicap_range : '-',
      fifth_handicap_range : '-',
      sixth_handicap_range : '-'
    }
  ];

  stats = null;

  clubList: any[];

  constructor(
    private _ProsService: ProsService,
    private _StatisticsService: StatisticsService,
    private modalService: BsModalService,
    private _AlertService: AlertService,
    private extValidation: FileExtentionValidatorDirective,
    private fb: FormBuilder,
    private env: EnvService
  ) {
    this.environtment = this.env.env;
  }

  ngOnInit() {
    this.clubFilterFormInit();
    this.getClubList();
    this.getOverallStatistics('');

    this.modalService.onHide
      .subscribe(res => {
        if (this.importCSVCoursesForm !== undefined) {
          this.importCSVCoursesForm.reset();
        }
      });
  }

  public importCSVCoursesFormInit(): void {
    this.importCSVCoursesForm = this.fb.group({
      csv_file: [ null, [FileValidator.validate] ]
    });
  }
  public clubFilterFormInit(): void {
    this.clubFilterForm = this.fb.group({
      club: [ null, [] ]
    });
  }
  public getClubList(): void {
    this._ProsService.getClubList()
      .subscribe(res => {
        this.clubList = res['result'];
      }, err => {
        console.log(err);
      })
  }
  public clubFilterSubmit(e): void {
    console.log(e);
    this.getOverallStatistics((e) ? e.id : '');
  }

  openImportCSVCoursesModal(template: TemplateRef<any>) {
    this.importCSVCoursesFormInit();
    this.modalRef = this.modalService.show(
      template,
      Object['assign']( { 'class': 'importCSVCoursesModal' })
    );
  }

  csvCoursesFileSubmit(e): void {
    this.btnLoading = true;
    this._StatisticsService.importCSVCourses(e.target[0].files[0])
      .subscribe(res => {
        this.btnLoading = false;
        this._AlertService.success('Alert.You_have_successfully_uploaded_csv_file_with_golf_courses');
        this.modalRef.hide();
      }, err => {
        console.log(err.error.message);
        this.btnLoading = false;
        this._AlertService.error(err.error.message);
      });
  }

  private getOverallStatistics(club_id): void {
    this._StatisticsService.getOverallStatistics({
        club_id: club_id
      }).subscribe(res => {
        console.log(res['result']['Plus handicap +0.1 - +5.0']);
        Object.keys(res['result']['Plus handicap +0.1 - +5.0']).forEach((obj, index) => {
          console.log(obj + ' = ' + index);
        });
        this.stats = res.result;
        this.scoringStats.forEach(obj => {
          Object.keys(res.result).forEach((child_obj, child_index) => {
            Object.keys(res.result[child_obj]).forEach((deep_obj, deep_index) => {
              if (deep_index === 0 || deep_index >= 2 && deep_index <= 12) {
                if (obj['param'] === 'SCORE' && deep_obj === 'avg_total_score') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }


                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'PAR 3' && deep_obj === 'avg_par_3') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'PAR 4' && deep_obj === 'avg_par_4') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'PAR 5' && deep_obj === 'avg_par_5') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'HOLE IN ONE' && deep_obj === 'avg_hole_in_one') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'ALBATROSS' && deep_obj === 'avg_albatross') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'EAGLES' && deep_obj === 'avg_eagles') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'BIRDIES' && deep_obj === 'avg_birdies') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'PARS' && deep_obj === 'avg_pars') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'BOGEY' && deep_obj === 'avg_bogeys') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'D.BOGEY' && deep_obj === 'avg_double_bogeys') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                } else if (obj['param'] === 'TRIPLE OR WORSE' && deep_obj === 'avg_worse') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                }
              }
            });
          })
        });
        this.firStats.forEach(obj => {
          Object.keys(res.result).forEach((child_obj, child_index) => {
            Object.keys(res.result[child_obj]).forEach((deep_obj, deep_index) => {
              if (deep_index > 12 && deep_index <= 16) {
                if (obj['param'] === 'Fir (%)' && deep_obj === 'fir_hit') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                } else if (obj['param'] === 'Missed Fir left (%)' && deep_obj === 'fir_miss_left') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                } else if (obj['param'] === 'Missed Fir right (%)' && deep_obj === 'fir_miss_right') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                }
              }
            });
          })
        });
        this.girStats.forEach(obj => {
          Object.keys(res.result).forEach((child_obj, child_index) => {
            Object.keys(res.result[child_obj]).forEach((deep_obj, deep_index) => {
              if (deep_index > 16 && deep_index <= 22) {
                if (obj['param'] === 'Gir (%)' && deep_obj === 'gir_hit') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }

                } else if (obj['param'] === 'Missed Gir left (%)' && deep_obj === 'gir_miss_left') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                } else if (obj['param'] === 'Missed Gir right (%)' && deep_obj === 'gir_miss_right') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                } else if (obj['param'] === 'Missed Gir long (%)' && deep_obj === 'gir_miss_long') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                } else if (obj['param'] === 'Missed Gir short (%)' && deep_obj === 'gir_miss_short') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                }
              }
            });
          })
        });
        this.upAndDownStats.forEach(obj => {
          Object.keys(res.result).forEach((child_obj, child_index) => {
            Object.keys(res.result[child_obj]).forEach((deep_obj, deep_index) => {
              if (deep_index > 22 && deep_index <= 26) {
                if (obj['param'] === 'Fringe average (%)' && deep_obj === 'uad_hit_fringe') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                } else if (obj['param'] === 'Rough average (%)' && deep_obj === 'uad_hit_rough') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                } else if (obj['param'] === 'Bunker average (%)' && deep_obj === 'uad_hit_bunker') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj] + '%';
                      break;
                  }
                }
              }
            })
          })
        });
        this.puttingStats.forEach(obj => {
          Object.keys(res.result).forEach((child_obj, child_index) => {
            Object.keys(res.result[child_obj]).forEach((deep_obj, deep_index) => {
              if (deep_index > 26 && deep_index <= 30) {
                // console.log(obj);
                // console.log(deep_obj);
                if (obj['param'] === 'Puts' && deep_obj === 'puts_all') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                }
                else if (obj['param'] === 'Short putts' && deep_obj === 'puts_hit_5') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                }
                else if (obj['param'] === 'Middle putts' && deep_obj === 'puts_hit_5_15') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                }
                else if (obj['param'] === 'Long putts' && deep_obj === 'puts_hit_15') {
                  switch (child_obj) {
                    case 'Plus handicap +0.1 - +5.0':
                      obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '0.0-5.0':
                      obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '5.1-10.0':
                      obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '10.1-15.0':
                      obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '15.1-20.0':
                      obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '20.1-54.1':
                      obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                    case '54.1 and higher':
                      obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                      break;
                  }
                }
              }
            })
          })
        });
        this.penaltiesStats.forEach(obj => {
        Object.keys(res.result).forEach((child_obj, child_index) => {
          Object.keys(res.result[child_obj]).forEach((deep_obj, deep_index) => {
            if (deep_index === 1) {
              // console.log(obj);
              // console.log(deep_obj);
              if (obj['param'] === 'Penalties' && deep_obj === 'avg_penalties') {
                switch (child_obj) {
                  case 'Plus handicap +0.1 - +5.0':
                    obj['plus_handicap'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                    break;
                  case '0.0-5.0':
                    obj['first_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                    break;
                  case '5.1-10.0':
                    obj['second_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                    break;
                  case '10.1-15.0':
                    obj['third_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                    break;
                  case '15.1-20.0':
                    obj['fourth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                    break;
                  case '20.1-54.1':
                    obj['fifth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                    break;
                  case '54.1 and higher':
                    obj['sixth_handicap_range'] = (res.result[child_obj][deep_obj] === null) ? '-' : res.result[child_obj][deep_obj];
                    break;
                }
              }
            }
          })
        })
        });

      }, err => {
        console.log(err)
      })
  }

}

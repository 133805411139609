import { Component, OnInit } from '@angular/core';
import {SharedService} from "../../../_services";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  app_version = '';

  constructor(
    private _SharedService: SharedService
  ) { }

  ngOnInit() {
    this._SharedService.getAppVersion()
      .subscribe(res => {
        console.log(res);
        this.app_version = res.result;
      })
  }

}

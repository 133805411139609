export class User {
  id: number;
  api_key: string;
  name: string;
  email: string;
  
  constructor(response: User) {
    this.id = response.id;
    this.api_key = response.api_key;
    this.name = response.name;
    this.email = response.email;
  }
  
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from "./_guards/index";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NotFound404Component } from "./not-found404/not-found404.component";
import { LoginComponent } from "./login/login.component";
import { StatisticsComponent } from "./statistics/statistics.component";
import { ClubsComponent } from "./clubs/clubs.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pros',
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'pros',
    loadChildren: './pros/pros.module#ProsModule'
  },
  {
    path: 'players',
    loadChildren: './players/players.module#PlayersModule'
  },
  {
    path: 'learning',
    loadChildren: './learning/learning.module#LearningModule'
  },
  {
    path: 'payments',
    loadChildren: './payments/payments.module#PaymentsModule'
  },
  {
    path: 'statistics',
    component: StatisticsComponent
  },
  {
    path: 'clubs',
    component: ClubsComponent
  },
  { path: '**', component: NotFound404Component }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled'
    })],
  declarations: []
})
export class AppRoutingModule {}

import { Directive, OnChanges } from '@angular/core';
import { AbstractControl } from "@angular/forms";

@Directive({
  selector: '[appFileExtentionValidator]'
})
export class FileExtentionValidatorDirective implements OnChanges {

  constructor() {}

  ngOnChanges() {}


  public fileValidator(type: string): any {
    console.log(type);

    return function (control: AbstractControl): {[key: string]: any} | null {
      console.log(control.value);
      if (control.value !== null) {
        let fileExtention = control.value.split('.');
        if (type !== fileExtention[fileExtention.length - 1]) {
          return {'invalidFormat': true}
        }
      }
      return null;
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class PlayersService {
  USER: any;
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) {}
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  public getPlayersList(data): Observable<any> {
    const url = `${this.env.apiBase}/web/operator/users`;
    this.getUserData();
    
    let options;
    let headers = new HttpHeaders()
      .append('ignoreLoadingBar', 'true');
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('club_id', data.club)
      .append('has_invites', data.has_invites)
      .append('search_value', data.search_value)
      .append('per_page', data.per_page)
      .append('page', data.page)
      .append('order_field', data.order_field)
      .append('order', data.order)
      .append('user_type', '1');
  
    if (data['ignoreBar']) {
      options = {
        params: params,
        headers: headers
      };
    } else {
      options = {
        params: params
      };
    }
    
    return this.http.get<Object>(url, options);
  }
  
  public getSinglePlayerData(data): Observable<any> {
    const url = `${this.env.apiBase}/web/operator/users`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('user_type', '1')
      .append('user_id', data.id);
    return this.http.get<Object>(url, {params});
  }
  
  public sendPushMessage(data): Observable<any> {
    const url = `${this.env.apiBase}/push`;
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('message', data.message)
      .append('send_to_all', data.send_to_all);
    return this.http.post<Object>(url, {}, {params});
  }
  
  public deletePlayer(data): Observable<any> {
    const url = `${this.env.apiBase}/user`;
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('user_id', data.user_id);
    return this.http.delete<Object>(url, {params});
  }

  public setAttribute(data): Observable<any> {
    const url = `${this.env.apiBase}/user/set-attributes`;
    let params = new HttpParams()
      .append('userId', this.USER.id);
    return this.http.post<Object>(url, data, {params});
  }

  public addExtraWeekOfTrial(data): Observable<any> {
    const url = `${this.env.apiBase}/user/set/trial`;
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('period', data.period)
      .append('user_ids', data.user_ids);
    return this.http.post<Object>(url, {}, {params});
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { EnvService } from "./env.service";


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  USER: any;
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) {}
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  public getPaymentsList(data): Observable<any> {
    const url = `${this.env.apiBase}/payout`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('status', data.status);
    return this.http.get<Object>(url, {params});
  }
  
  public getSinglePayment(data): Observable<any> {
    const url = `${this.env.apiBase}/payout`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('payout_id', data.id);
    return this.http.get<Object>(url, {params});
  }
  
  public changePaymentAmount(data): Observable<any> {
    const url = `${this.env.apiBase}/payout/operator`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id);
    return this.http.post<Object>(url, data,{params});
  }
  public changePaymentStatus(data): Observable<any> {
    const url = `${this.env.apiBase}/payout/operator`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id);
    return this.http.post<Object>(url, data,{params});
  }
  
  public exportPayments(data): Observable<any> {
    const url = `${this.env.apiBase}/payout`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('status', data.status)
      .append('export', '1');
    
    return this.http.get(url, {
      params: params,
      responseType: 'arraybuffer'
    });
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class LearningService {
  USER: any;

  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }

  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  public getLearningList(data): Observable<any> {
    const url = `${this.env.apiBase}/video`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('general_from', data.general_from)
      .append('general_to', data.general_to)
      .append('category_ids', data.category_ids)
      .append('week_date', data.week_date);
    return this.http.get<Object>(url, {params});
  }
  public getSingleLearningVideo(data): Observable<any> {
    const url = `${this.env.apiBase}/video`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('video_id', data.video_id);
    return this.http.get<Object>(url, {params});
  }
  public getAttributesList(): Observable<any> {
    const url = `${this.env.apiBase}/attribute`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id);
    return this.http.get<Object>(url, {params});
  }
  public getCategoriesList(): Observable<any> {
    const url = `${this.env.apiBase}/video/category`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id);
    return this.http.get<Object>(url, {params});
  }
  public createLearningVideo(data): Observable<any> {
    const url = `${this.env.apiBase}/video`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id);
    return this.http.post<Object>(url, data, {params});
  }

  public importCSVVideoList(file): Observable<any> {
    const url = `${this.env.apiBase}/video/excel`;
    const formData: FormData = new FormData();
    formData.append('xls', file, file.name);
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id);

    return this.http.post(url,  formData, {params});
  }


  public deleteLearningVideo(data): Observable<any> {
    const url = `${this.env.apiBase}/video`;
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('ids', data.ids);
    return this.http.delete<Object>(url, {params});
  }

}

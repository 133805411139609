
// INTERNAL MODULES
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// DIRECTIVES
import { PwdEqualityDirective, FileExtentionValidatorDirective, NumbersValidatorDirective, FileValidator, FileValueAccessor, AwsTunnelDirective } from "../../_directives";

// COMPONENTS
import { AlertComponent } from "../components/alert/alert.component";

// PIPES
import { FilterPipe, OrderByPipe } from '../../_pipes';

// EXTERNAL MODULES
import { NgxPaginationModule } from "ngx-pagination";
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule, ModalModule, BsDatepickerModule } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    FilterPipe,
    OrderByPipe,

    PwdEqualityDirective,
    FileExtentionValidatorDirective,
    NumbersValidatorDirective,
    FileValueAccessor,
    FileValidator,
    AwsTunnelDirective,

    AlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    NgSelectModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    NgSelectModule,
    ModalModule,
    BsDatepickerModule,
    BsDropdownModule,

    FilterPipe,
    OrderByPipe,

    PwdEqualityDirective,
    FileExtentionValidatorDirective,
    NumbersValidatorDirective,
    FileValueAccessor,
    FileValidator,
    AwsTunnelDirective,

    AlertComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [BsModalService]
    };
  }
}

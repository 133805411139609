import { Injectable, EventEmitter } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {EnvService} from "./env.service";

@Injectable()
export class SharedService {

  changeLanguageEvent: EventEmitter<any> = new EventEmitter();

  private mobileUserData = new Subject<any>();
  private childLoad = new Subject<any>();
  passMobileUserData$ = this.mobileUserData.asObservable();
  childLoadEmit$ = this.childLoad.asObservable();

  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }

  passData(data) {
    this.mobileUserData.next(data);
  }
  emitChanges(data) {
    this.childLoad.next(data);
  }
  public getAppVersion(): Observable<any> {
    const url = `${this.env.apiBase}/version`;
    return this.http.get<Object>(url, {});
  }


}

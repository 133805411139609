import {Component, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { ClubsService, ProsService, AlertService } from "../_services/index";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {BsModalService} from "ngx-bootstrap";
import {FileExtentionValidatorDirective, FileValidator} from "../_directives/index";
import { EnvService } from "../_services/env.service";

@Component({
  selector: 'app-statistics',
  templateUrl: './clubs.component.html',
  styleUrls: ['./clubs.component.less'],
  providers: [FileExtentionValidatorDirective]
})
export class ClubsComponent implements OnInit {

  clubFilterForm: FormGroup;
  importCSVCoursesForm: FormGroup;

  modalRef: BsModalRef;
  btnLoading = false;

  environtment: any;

  stats = null;
  clubList = null;

  p: number = 1;

  constructor(
    private _ClubsService: ClubsService,
    private modalService: BsModalService,
    private _AlertService: AlertService,
    private extValidation: FileExtentionValidatorDirective,
    private fb: FormBuilder,
    private env: EnvService
  ) {
    this.environtment = this.env.env;
  }

  ngOnInit() {
    this.getClubList();

    this.modalService.onHide
      .subscribe(res => {
        if (this.importCSVCoursesForm !== undefined) {
          this.importCSVCoursesForm.reset();
        }
      });
  }

  public importCSVCoursesFormInit(): void {
    this.importCSVCoursesForm = this.fb.group({
      csv_file: [ null, [FileValidator.validate] ]
    });
  }
  public getClubList(): void {
    this._ClubsService.getClubList()
      .subscribe(res => {
        this.clubList = res['result'];
        console.log(this.clubList)
      }, err => {
        console.log(err);
      })
  }

  openImportCSVCoursesModal(template: TemplateRef<any>) {
    this.importCSVCoursesFormInit();

    this.modalRef = this.modalService.show(
      template,
      Object['assign']( { 'class': 'importCSVCoursesModal' })
    );
  }

  csvCoursesFileSubmit(e): void {
    this.btnLoading = true;
    console.log(e);
    this._ClubsService.importCSVCourses(e.target[0].files[0])
      .subscribe(res => {
        this.btnLoading = false;
        this._AlertService.success('Alert.You_have_successfully_uploaded_csv_file_with_golf_courses');
        this.modalRef.hide();
      }, err => {
        console.log(err.error.message);
        this.btnLoading = false;
        this._AlertService.error(err.error.message);
      });
  }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  USER: any;

  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }

  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  public getSinglePlayerStatistics(data): Observable<any> {
    const url = `${this.env.apiBase}/user/statistic`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('user_id', data.user_id);
    return this.http.get<Object>(url, {params});
  }

  public getOverallStatistics(data): Observable<any> {
    const url = `${this.env.apiBase}/user/statistic`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id)
      .append('club_id', data.club_id);
    return this.http.get<Object>(url, {params});
  }

  public importCSVCourses(file): Observable<any> {
    const url = `${this.env.apiBase}/course/import-csv`;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.id);

    return this.http.post(url,  formData, {params});
  }

}

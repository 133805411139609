<header>
  <div class="container">
    <div class="row">
      <div class="col col-12">
        <ul class="nav-menu main-nav-menu">
          <li class="nav-item logo">
            <a [routerLink]="['/']">
              <img src="{{'assets/images/splashgolf.png'}}" alt="mygolfstat-logo">
            </a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'" *ngIf="USER">
            <a [routerLink]="['/pros']">{{'Header.Pros' | translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'" *ngIf="USER">
            <a [routerLink]="['/players']">{{'Header.Players' | translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'" *ngIf="USER">
            <a [routerLink]="['/learning']">{{'Header.Learning_content' | translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'" *ngIf="USER">
            <a [routerLink]="['/payments']">{{'Header.Payments' | translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'" *ngIf="USER">
            <a [routerLink]="['/statistics']">{{'Header.Statistics' | translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="'active'" *ngIf="USER">
            <a [routerLink]="['/clubs']">{{'Header.Clubs' | translate}}</a>
          </li>
        </ul>
        <ul class="nav-menu">
          <li class="nav-item language">
            <div class="dropdown lang_dropdown_menu_wrapper" >
              <button class="lang_dropdown_menu_btn" type="button" id="lang_dropdown_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{lang.active}} <i class="fas fa-chevron-down"></i>
              </button>
              <div class="lang-dropdown-menu dropdown-menu" aria-labelledby="lang_dropdown_menu">
                <a *ngIf="lang.active !== 'en'" (click)="useLanguage('en')">EN</a>
                <a *ngIf="lang.active !== 'nl'" (click)="useLanguage('nl')">NL</a>
              </div>
            </div>
          </li>
          <li class="nav-item logout" *ngIf="USER">
            <a class="logout_btn" (click)="logout()">{{'Header.Logout' | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from "@angular/forms";

@Directive({
  selector: '[appPwdEquality]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: PwdEqualityDirective,
    multi: true
  }]
})
export class PwdEqualityDirective implements Validator {
  @Input() appPwdEqualityValidator: string;
  
  validate(control: AbstractControl): {[key: string]: any} | null {
    const controlToCompare = control.parent.get(this.appPwdEqualityValidator);
    if (controlToCompare && controlToCompare.value !== control.value) {
      return {'notEqual': true};
    }
    return null;
  }
  
  constructor() { }
  
}
